import { Chip, Collapse, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneIcon from "@material-ui/icons/Done";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import UndoIcon from "@material-ui/icons/Undo";
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";

import { AuthContext } from "../../../context/AuthContext";
import legacyConfig from "../../../legacyConfig";
import PrintOrderButton from "../button/PrintLegacyOrderButton";
import ConfirmMarkCompleteDialog from "./ConfirmMarkCompleteDialog";
import NotifyDelayDialog from "./NotifyDelayDialog";
import OrderStatusText from "./OrderStatusText";
import QuickViewDialog from "./QuickViewDialog";
import RefundDialog from "./RefundDialog";
import TransferOrderDialog from "./TransferOrderDialog";

const stalenessGradient = [
  "#FFFFFF",
  "#FFFFFF",
  "#FFF7EB",
  "#FFEFD7",
  "#FFE7C4",
  "#FFDFB0",
  "#FFD79C",
  "#FFCF88",
  "#FFC775",
  "#FFBF61",
  "#FFB74D",
  "#FCAF51",
  "#F9A855",
  "#F6A05A",
  "#F3995E",
  "#F19162",
  "#EE8A66",
  "#EB826B",
  "#E87B6F",
  "#E57373",
];

const earlinessGradient = [
  "#FFFFFF",
  "#EEF7FE",
  "#DDEFFD",
  "#CBE6FC",
  "#BADEFB",
  "#A9D6FA",
  "#98CEF9",
  "#86C5F8",
  "#75BDF7",
  "#64B5F6",
];

const calcStaleness = (timestamp, pickup) => {
  const MAX_STALENESS_IN_MILLIS = 60 * 60 * 1000; // 30 minutes
  let currentTime = new Date();
  let orderTime = new Date(timestamp);
  if (currentTime.getDate() === orderTime.getDate()) {
    // Same day
    if (pickup !== "now") {
      let magnitude =
        currentTime.getTime() -
        Date.parse(new Date().toDateString() + " " + pickup);
      let sign = magnitude > 0 ? 1 : -1;
      return (
        sign *
        Math.min(Math.abs((magnitude * 100) / MAX_STALENESS_IN_MILLIS), 100)
      ).toFixed();
    } else {
      return Math.min(
        ((currentTime.getTime() - orderTime) * 100) / MAX_STALENESS_IN_MILLIS,
        100
      ).toFixed();
    }
  }
  return 100;
};

const getCardStyle = (staleness, orderStatus, theme) => {
  if (parseInt(orderStatus) >= 2) {
    return {
      backgroundColor: theme.palette.success.light,
    };
  } else if (parseInt(orderStatus) == 1) {
    let color = "#ffffff";
    if (staleness > 0) {
      if (staleness == 100) {
        color = theme.palette.error.light;
      } else {
        color = stalenessGradient[Math.floor(staleness / 5)];
      }
    } else {
      if (staleness == -100) {
        color = theme.palette.info.light;
      } else if (staleness < -50) {
        color =
          earlinessGradient[Math.floor(Math.abs(parseInt(staleness) + 50) / 5)];
      }
    }
    return {
      backgroundColor: color,
    };
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    transition: "background-color 1s ease",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      width: "120px",
    },
  },
  spacing: {
    margin: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: 32,
  },
  animatedItem: {
    animation: `$flash 1s infinite`,
  },
  "@keyframes flash": {
    "0%": {
      backgroundColor: "#ff9900",
    },
    "80%": {
      backgroundColor: "#ffffff",
    },
    "100%": {
      backgroundColor: "#ff9900",
    },
  },
  cardActions: {
    flexDirection: "column",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  confirmColor: {
    color: theme.palette.success.dark,
  },
  rejectColor: {
    color: theme.palette.error.dark,
  },
  listItemText: {
    maxWidth: "70%",
  },
  changeRequestSection: {
    backgroundColor: "white",
  },
}));

const getTimestring = (timestamp) => {
  return new Date(timestamp).toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
};

export default function OrderCard(props) {
  const classes = useStyles();
  const theme = useTheme();

  const authContext = React.useContext(AuthContext);
  const [quickViewOpen, setQuickViewOpen] = React.useState(false);
  const [transferOrderOpen, setTransferOrderOpen] = React.useState(false);
  const [markCompleteOpen, setMarkCompleteOpen] = React.useState(false);
  const [notifyDelayOpen, setNotifyDelayOpen] = React.useState(false);
  const [refundOpen, setRefundOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const self = React.useRef(null);
  const changesRef = React.useRef(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleQuickViewOpen = () => {
    setQuickViewOpen(true);
    handleMenuClose();
  };

  const handleQuickViewClose = () => {
    setQuickViewOpen(false);
  };

  const handleTransferOrderOpen = () => {
    setTransferOrderOpen(true);
    handleMenuClose();
  };

  const handleTransferOrderClose = () => {
    setTransferOrderOpen(false);
  };

  const handleMarkCompleteOpen = () => {
    setMarkCompleteOpen(true);
    handleMenuClose();
  };

  const handleMarkCompleteClose = () => {
    setMarkCompleteOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSync = (mutatedItem) => {
    props.handleSync(props.id, mutatedItem);
  };

  const handleNotifyDelayOpen = () => {
    setNotifyDelayOpen(true);
    handleMenuClose();
  };

  const handleNotifyDelayClose = () => {
    setNotifyDelayOpen(false);
  };

  const handleRefundOpen = () => {
    setRefundOpen(true);
    handleMenuClose();
  };

  const handleRefundClose = () => {
    setRefundOpen(false);
  };

  const handleAccept = async () => {
    let mutatedItem = JSON.parse(JSON.stringify(props.item));
    mutatedItem.ORDER_STATUS = "1";
    handleSync(mutatedItem);
    try {
      await axios.get(
        legacyConfig.endpoint +
          "/updateorder?uuid=" +
          props.item.UUID +
          "&order_status=1"
      );
      enqueueSnackbar(
        "Order " + props.item.UUID.substring(0, 8).toUpperCase() + " accepted",
        {
          variant: "success",
        }
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to accept Order " +
          props.item.UUID.substring(0, 8).toUpperCase(),
        {
          variant: "error",
        }
      );
    }
  };

  const handleNotify = async () => {
    let mutatedItem = JSON.parse(JSON.stringify(props.item));
    mutatedItem.ORDER_STATUS = "2";
    handleSync(mutatedItem);
    try {
      await axios.get(
        legacyConfig.endpoint +
          "/updateorder?uuid=" +
          props.item.UUID +
          "&order_status=2"
      );
      enqueueSnackbar(
        "Order " +
          props.item.UUID.substring(0, 8).toUpperCase() +
          " notified for pickup",
        {
          variant: "success",
        }
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to notify pickup for Order " +
          props.item.UUID.substring(0, 8).toUpperCase(),
        {
          variant: "error",
        }
      );
    }
  };

  const handleNotifyDelay = async (id, message) => {
    handleNotifyDelayClose();
    try {
      await axios.post(legacyConfig.endpoint + "/notifyDelay", {
        uuid: id,
        value: message,
      });
      enqueueSnackbar(
        "Order " +
          props.item.UUID.substring(0, 8).toUpperCase() +
          " notified for delay",
        {
          variant: "success",
        }
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to notify delay for Order " +
          props.item.UUID.substring(0, 8).toUpperCase(),
        {
          variant: "error",
        }
      );
    }
  };

  const handleRefund = async (id, amount, employee, reason, items) => {
    handleRefundClose();
    let postTaxAmount = Math.floor(amount * 1.05);
    try {
      let result = await axios.post(
        legacyConfig.endpoint + "/refundPayment",
        {
          uuid: id,
          amount: postTaxAmount,
          employee: employee,
          reason: reason,
          items: items,
        },
        {
          headers: {
            Authorization: authContext.signInUserSession.idToken.jwtToken,
          },
        }
      );
      if (result.data == "200") {
        enqueueSnackbar(
          `Order ${props.item.UUID.substring(
            0,
            8
          ).toUpperCase()} was refunded $${(amount / 100).toFixed(2)}`,
          {
            variant: "success",
          }
        );
      } else {
        throw new Error("Refund failed");
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to issue refund for Order " +
          props.item.UUID.substring(0, 8).toUpperCase(),
        {
          variant: "error",
        }
      );
    }
  };

  const handleMarkComplete = async (id) => {
    let mutatedItem = JSON.parse(JSON.stringify(props.item));
    mutatedItem.ORDER_STATUS = "3";
    handleSync(mutatedItem);
    handleMarkCompleteClose();
    try {
      await axios.get(
        legacyConfig.endpoint + "/updateorder?uuid=" + id + "&order_status=3"
      );
      enqueueSnackbar(
        "Order " +
          props.item.UUID.substring(0, 8).toUpperCase() +
          " marked as complete",
        {
          variant: "success",
        }
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to mark Order " +
          props.item.UUID.substring(0, 8).toUpperCase() +
          " as complete",
        {
          variant: "error",
        }
      );
    }
  };

  const handleTransfer = async (id, targetStoreId) => {
    let mutatedItem = null;
    handleSync(mutatedItem);
    handleTransferOrderClose();
    try {
      await axios.post(legacyConfig.endpoint + "/transferOrder", {
        uuid: id,
        store: targetStoreId,
      });
      enqueueSnackbar(
        "Order " +
          props.item.UUID.substring(0, 8).toUpperCase() +
          " transfered to " +
          targetStoreId,
        {
          variant: "success",
        }
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to transfer Order " +
          props.item.UUID.substring(0, 8).toUpperCase() +
          " to " +
          targetStoreId,
        {
          variant: "error",
        }
      );
    }
  };

  const toggleShowChangeRequest = () => {
    setShowChangeRequest(!showChangeRequest);
  };

  const [cardStyle, setCardStyle] = React.useState();
  const [orderObject, setOrderObject] = React.useState();
  const [timeString, setTimeString] = React.useState();
  const [shortId, setShortId] = React.useState();

  React.useEffect(() => {
    setTimeString(getTimestring(props.item.TIMESTAMP));
    setCardStyle(
      getCardStyle(
        calcStaleness(props.item.TIMESTAMP, props.item.PICKUP),
        props.item.ORDER_STATUS,
        theme
      )
    );
  }, [props.item.TIMESTAMP, props.item.PICKUP, props.item.ORDER_STATUS]);

  React.useEffect(() => {
    setOrderObject(JSON.parse(props.item.ORDER_JSON));
  }, [props.item.ORDER_JSON]);

  React.useEffect(() => {
    setShortId(props.item.UUID.substring(0, 8).toUpperCase());
  }, [props.item.UUID]);

  React.useEffect(() => {
    if (props.item.ORDER_STATUS == "0") {
      props.registerRef(props.item.UUID, self);
    } else {
      props.unregisterRef(props.item.UUID);
    }
  }, [props.item.ORDER_STATUS, props.item.UUID]);

  const [showChangeRequest, setShowChangeRequest] = React.useState(false);
  const [changeRequests, setChangeRequests] = React.useState([]);

  const handleShowChangeRequest = () => {
    setShowChangeRequest(true);
  };

  const fetchChangeRequest = async (uuid) => {
    const graphqlQuery = `query listChangeRequests {
      listChangeRequests(UUID: "${uuid}") {
        nextToken
        items {
          STATUS
          CONTENT
          TIMESTAMP
          UUID
        }
      }
    }          
    `;
    try {
      let graphqlQueryResult = await API.graphql(
        graphqlOperation(graphqlQuery)
      );
      let items = graphqlQueryResult.data.listChangeRequests.items;
      setChangeRequests(items);
      if (items.filter((item) => item.STATUS == 0).length > 0) {
        props.registerUnackRef(uuid, changesRef, handleShowChangeRequest);
      } else {
        props.unregisterUnackRef(uuid);
      }
    } catch (err) {}
  };

  const handleUpdateChangeRequest = (uuid, timestamp, accept) => async () => {
    try {
      let response = await axios.post(
        legacyConfig.endpoint + "/updateChangeRequest",
        {
          uuid: uuid,
          timestamp: timestamp,
          status: accept ? 1 : 2,
        },
        {
          headers: {
            Authorization: authContext.signInUserSession.idToken.jwtToken,
          },
        }
      );
      if (response.data.statusCode != 200) {
        throw Error("Failed to update change request");
      } else {
        enqueueSnackbar(
          `Change request ${
            accept ? "accepted" : "rejected"
          } for order ${shortId}`,
          {
            variant: "success",
          }
        );
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Failed to update change request`, {
        variant: "error",
      });
    } finally {
      fetchChangeRequest(uuid);
    }
  };

  React.useEffect(() => {
    fetchChangeRequest(props.item.UUID);
  }, [props.item.UUID]);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card
        className={`${classes.root} ${
          props.item.ORDER_STATUS == "0" ? classes.animatedItem : ""
        }`}
        style={cardStyle}
        ref={self}
      >
        <CardHeader
          action={
            <IconButton aria-label="menu" onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
          }
          title={shortId}
          subheader={timeString}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleQuickViewOpen}>
            <ListItemIcon className={classes.listItemIcon}>
              <SearchIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Quick View</Typography>
          </MenuItem>
          <MenuItem onClick={handleTransferOrderOpen}>
            <ListItemIcon className={classes.listItemIcon}>
              <SwapHorizIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Transfer</Typography>
          </MenuItem>
          <MenuItem onClick={handleMarkCompleteOpen}>
            <ListItemIcon className={classes.listItemIcon}>
              <DoneIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Mark Complete</Typography>
          </MenuItem>
          <MenuItem onClick={handleNotifyDelayOpen}>
            <ListItemIcon className={classes.listItemIcon}>
              <ScheduleIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Notify Delay</Typography>
          </MenuItem>
          <MenuItem onClick={handleRefundOpen}>
            <ListItemIcon className={classes.listItemIcon}>
              <UndoIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Refund Customer</Typography>
          </MenuItem>
        </Menu>
        <QuickViewDialog
          open={quickViewOpen}
          handleClose={handleQuickViewClose}
          id={props.item.UUID.substring(0, 8).toUpperCase()}
          item={props.item.ORDER_JSON}
          comment={props.item.COMMENT}
        />
        <TransferOrderDialog
          open={transferOrderOpen}
          handleTransfer={handleTransfer}
          handleClose={handleTransferOrderClose}
          id={props.item.UUID}
        />
        <ConfirmMarkCompleteDialog
          open={markCompleteOpen}
          handleMarkComplete={handleMarkComplete}
          handleClose={handleMarkCompleteClose}
          id={props.item.UUID}
        />
        <NotifyDelayDialog
          open={notifyDelayOpen}
          handleSend={handleNotifyDelay}
          handleClose={handleNotifyDelayClose}
          id={props.item.UUID}
        />
        <RefundDialog
          open={refundOpen}
          handleRefund={handleRefund}
          handleClose={handleRefundClose}
          id={props.item.UUID}
          order={props.item.ORDER_JSON}
        />
        <CardContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography variant="h6" color="textPrimary">
              {props.item.CUSTOMER_NAME}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {props.item.CUSTOMER_EMAIL}
            </Typography>
            <Typography variant="overline" color="textSecondary">
              {"Pickup Time: " +
                (props.item.PICKUP ? props.item.PICKUP : "now")}
            </Typography>
            <OrderStatusText status={props.item.ORDER_STATUS} />
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.buttons}
          >
            {props.item.ORDER_STATUS === "0" ? (
              <PrintOrderButton
                uuid={props.item.UUID}
                timestamp={timeString}
                pickup={props.item.PICKUP ? props.item.PICKUP : "now"}
                name={props.item.CUSTOMER_NAME}
                email={props.item.CUSTOMER_EMAIL}
                comment={props.item.COMMENT}
                tip={props.item.TIP ? props.item.TIP : "0.00"}
                order={orderObject}
                onClick={handleAccept}
                variant="contained"
                label="Accept"
              />
            ) : (
              <React.Fragment>
                <PrintOrderButton
                  uuid={props.item.UUID}
                  timestamp={timeString}
                  pickup={props.item.PICKUP ? props.item.PICKUP : "now"}
                  name={props.item.CUSTOMER_NAME}
                  email={props.item.CUSTOMER_EMAIL}
                  comment={props.item.COMMENT}
                  tip={props.item.TIP ? props.item.TIP : "0.00"}
                  order={orderObject}
                />

                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={handleNotify}
                >
                  Notify
                </Button>
              </React.Fragment>
            )}
          </Grid>
          {changeRequests.length > 0 ? (
            <Grid className={classes.spacing}>
              <Button
                color="primary"
                endIcon={
                  <ExpandMoreIcon
                    className={`${classes.expand} ${
                      showChangeRequest ? classes.expandOpen : ""
                    }`}
                  >
                    Show More
                  </ExpandMoreIcon>
                }
                onClick={toggleShowChangeRequest}
              >
                Change Requested
              </Button>
            </Grid>
          ) : null}
        </CardActions>
        <Collapse in={showChangeRequest} timeout="auto" ref={changesRef}>
          <CardContent className={classes.changeRequestSection}>
            <Typography variant="h6">Change Request History</Typography>
            <List>
              {changeRequests.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    className={classes.listItemText}
                    primary={item.CONTENT}
                    secondary={`${new Date(
                      item.TIMESTAMP
                    ).toLocaleTimeString()}`}
                  />
                  <ListItemSecondaryAction>
                    <Grid container className={classes.cardActions}>
                      {item.STATUS == 1 ? (
                        <Chip
                          color="secondary"
                          label={
                            <Typography variant="overline">Accepted</Typography>
                          }
                          icon={<CheckCircleIcon />}
                          variant="outlined"
                        ></Chip>
                      ) : item.STATUS == 2 ? (
                        <Chip
                          color="secondary"
                          label={
                            <Typography variant="overline">Rejected</Typography>
                          }
                          icon={<CancelIcon />}
                          variant="outlined"
                        ></Chip>
                      ) : (
                        <React.Fragment>
                          <Chip
                            color="secondary"
                            label={
                              <Typography variant="overline">Accept</Typography>
                            }
                            icon={<CheckCircleIcon />}
                            onClick={handleUpdateChangeRequest(
                              item.UUID,
                              item.TIMESTAMP,
                              true
                            )}
                            className={classes.spacing}
                          ></Chip>
                          <Chip
                            color="secondary"
                            label={
                              <Typography variant="overline">Reject</Typography>
                            }
                            icon={<CancelIcon />}
                            onClick={handleUpdateChangeRequest(
                              item.UUID,
                              item.TIMESTAMP,
                              false
                            )}
                            className={classes.spacing}
                          ></Chip>
                        </React.Fragment>
                      )}
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
