import {
  Button,
  Container,
  Dialog,
  DialogContentText,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { API, graphqlOperation } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function TransferOrderDialog({
  open,
  shortId,
  onHandleTransfer,
  onHandleClose,
}) {
  const [stores, setStores] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const listStores = async (nextToken) => {
    try {
      setLoading(true);
      const query = `query MyQuery {
      listStoresBetas${nextToken ? `(nextToken: "${nextToken}")` : ""} {
      items {
        name
        store_id
      }
      nextToken
    }
  }
  `;

      let queryResult = await API.graphql(graphqlOperation(query));
      let nextToken_ = queryResult.data.listStoresBetas.nextToken;
      setStores((stores_) =>
        [...stores_].concat(queryResult.data.listStoresBetas.items)
      );
      if (nextToken_) {
        await listStores(nextToken_);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    listStores(null);
    return () => {
      setStores([]);
    };
  }, [open]);

  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleTransfer = () => {
    onHandleTransfer(value);
  };

  const handleClose = () => {
    setValue(null);
    onHandleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="transfer-dialog-title" onClose={handleClose}>
        {`Transfer ${shortId}`}
      </DialogTitle>
      <DialogContent>
        <Container>
          {loading ? (
            <CircularProgress />
          ) : (
            <FormControl component="fieldset" className={classes.root}>
              <FormLabel component="legend">Select store:</FormLabel>
              <RadioGroup name="store" onChange={handleChange}>
                {stores.map((store, index) => (
                  <FormControlLabel
                    key={index}
                    value={store.store_id}
                    control={<Radio />}
                    label={store.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
          <DialogContentText>
            This order will be transferred to the selected store as an open
            order and removed from this store's queue.
          </DialogContentText>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleTransfer}
          color="primary"
          disabled={value == null}
        >
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
