import { Grid, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { ContactSupportOutlined } from "@material-ui/icons";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";

import { MetadataContext } from "../../context/MetadataContext";
import useInterval from "../../hooks/useInterval";
import LegacyOrderCard from "../layout/card/LegacyOrderCard";
import MobileOrderCard from "../layout/card/mobile/MobileOrderCard";
import AlarmDialog from "../layout/content/AlarmDialog";
import SKUTableAPIClient from "./SKUTableAPIClient";

// const getTimestamp

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: "80vh",
  },
}));

const compareOrderStatus = (a, b) => {
  let a_status;
  if (a.status == "created") {
    a_status = 0;
  } else if (a.status == "in_progress") {
    a_status = 1;
  } else if (a.status == "completed") {
    a_status = 2;
  } else {
    a_status = parseInt(a.ORDER_STATUS);
  }

  let b_status;
  if (b.status == "created") {
    b_status = 0;
  } else if (b.status == "in_progress") {
    b_status = 1;
  } else if (b.status == "completed") {
    b_status = 2;
  } else {
    b_status = parseInt(b.ORDER_STATUS);
  }

  if (a_status == 1 && b_status == 0) {
    return 0;
  } else if (a_status == 0 && b_status == 1) {
    return 0;
  }
  return a_status - b_status;
};

const compareTimestamp = (a, b) => {
  let a_time = a.TIMESTAMP ? a.TIMESTAMP : a.timestamp;
  let b_time = b.TIMESTAMP ? b.TIMESTAMP : b.timestamp;
  return a_time - b_time;
};

const countOpenOrders = (items) => {
  let count = 0;
  for (const item of items) {
    if (item.ORDER_STATUS === "0" || item.status == "created") count++;
  }
  // return 1;
  return count;
};

export default function LegacyOrderAPIClient(props) {
  const updateOpenOrders = (value) => {
    props.handleUpdateOpenOrders(value);
    setOpenOrders(value);
  };

  const classes = useStyles();
  const [orders, setOrders] = React.useState([]);
  const [responseStatus, setResponseStatus] = React.useState();
  const metadataContext = React.useContext(MetadataContext);
  const [delayFetch, setDelayFetch] = React.useState(false);
  const [openOrders, setOpenOrders] = React.useState(0);

  const yesterdayHr = localStorage.getItem("Settings.fetchTime")
    ? localStorage.getItem("Settings.fetchTime")
    : "20";

  const syncDate = new Date();

  syncDate.setDate(syncDate.getDate() - 1);
  syncDate.setHours(parseInt(yesterdayHr));
  syncDate.setMinutes(0);
  syncDate.setSeconds(0);

  const handleSync = (id, mutatedItem) => {
    if (orders.length > 0) {
      let mutatedItems = [];
      for (let item of orders) {
        if (item.UUID == id || item.order_id == id) {
          if (mutatedItem != null) {
            mutatedItems.push(mutatedItem);
          }
        } else {
          mutatedItems.push(JSON.parse(JSON.stringify(item)));
        }
      }
      mutatedItems.sort(compareTimestamp);
      mutatedItems.sort(compareOrderStatus);
      setOrders(mutatedItems);
      let openOrders = countOpenOrders(mutatedItems);
      updateOpenOrders(openOrders);
      if (openOrders > 0) {
        props.handleAlarmOn();
      } else {
        props.handleAlarmOff();
      }
      setDelayFetch(true);
    }
  };

  const handleAck = () => {
    setDelayFetch(true);
    props.handleAlarmOff();
  };

  const doFetch = () => {
    const fetchOrders = async () => {
      try {
        let storeId = metadataContext.storeId;

        let items = [];

        const legacyQuery =
          `query listOpenOrders {
            listLegacyOrder(TIMESTAMP: ` +
          syncDate.getTime() +
          `, STORE: "` +
          storeId +
          `",first:1000) {
              nextToken
              items {
                UUID
                ORDER_STATUS
                STORE
                TIMESTAMP
                COMMENT
                CUSTOMER_EMAIL
                CUSTOMER_NAME
                CUSTOMER_PHONE
                ORDER_JSON
                PICKUP
                TIP
              }
            }
          }
          `;

        let legacyQueryResult = await API.graphql(
          graphqlOperation(legacyQuery)
        );

        if (legacyQueryResult) {
          items = items.concat(legacyQueryResult.data.listLegacyOrder.items);
        }

        let mobileStoreId = metadataContext.mobileStoreId;
        // queryMobileOrdersBetasByStoreIdTimestampIndex(timestamp: ${syncDate.getTime()}, store_id: "${mobileStoreId}", first:1000) {
        const mobileQuery = `query listOpenOrders {
          queryMobileOrdersBetasByStoreIdTimestampIndex(timestamp: ${syncDate.getTime()}, store_id: "${mobileStoreId}", first:1000) {
              nextToken
              items {
                order_id
                user_id
                store_id
                timestamp
                status
                customer_email
                customer_name
                comment
                immutableLineItems
                pickUpTime
                tip
                bag
                utensils
              }
            }
          }
          `;

        let mobileQueryResult = await API.graphql(
          graphqlOperation(mobileQuery)
        );

        if (mobileQueryResult) {
          let mobileOrderItems =
            mobileQueryResult.data.queryMobileOrdersBetasByStoreIdTimestampIndex
              .items;
          items = items.concat(
            mobileOrderItems.map((item) =>
              Object.assign(item, {
                mobileOrder: true,
                immutableLineItems: JSON.parse(item.immutableLineItems),
              })
            )
          );
        }

        // for (let item of items) {
        //   item.ORDER_STATUS = "0";
        // }
        items.sort(compareTimestamp);
        items.sort(compareOrderStatus);
        setResponseStatus(200);
        setOrders(items);
        let openOrders = countOpenOrders(items);
        updateOpenOrders(openOrders);
        if (openOrders > 0) {
          // if (Math.random() > 0.5) {
          props.handleAlarmOn();
        } else {
          props.handleAlarmOff();
        }
      } catch (err) {
        setResponseStatus(503);
        setOrders([]);
        console.error(err);
      }
    };
    if (metadataContext) {
      if (delayFetch) {
        setDelayFetch(false);
      } else {
        fetchOrders();
      }
    }
  };

  React.useEffect(doFetch, [metadataContext]);
  useInterval(doFetch, 10000);

  if (responseStatus == null) {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.fullHeight}
      >
        <CircularProgress />
      </Grid>
    );
  } else {
    return responseStatus === 200 ? (
      <React.Fragment>
        {orders.length == 0 ? (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
            className={classes.fullHeight}
          >
            <Typography>
              {"Last close: " +
                syncDate.toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                })}
            </Typography>
            <Typography fontStyle="oblique" variant="subtitle2">
              New orders will automatically show up here
            </Typography>
          </Grid>
        ) : (
          <React.Fragment>
            {orders.map((item, index) => {
              return item.mobileOrder ? (
                <MobileOrderCard
                  key={item.order_id}
                  item={item}
                  onHandleSync={handleSync}
                  registerRef={props.registerRef}
                  unregisterRef={props.unregisterRef}
                />
              ) : (
                <LegacyOrderCard
                  key={item.UUID}
                  id={item.UUID}
                  item={item}
                  handleSync={handleSync}
                  registerRef={props.registerRef}
                  unregisterRef={props.unregisterRef}
                  registerUnackRef={props.registerUnackRef}
                  unregisterUnackRef={props.unregisterUnackRef}
                />
              );
            })}
          </React.Fragment>
        )}
        <AlarmDialog
          open={props.alarmState}
          count={openOrders}
          handleAck={handleAck}
        />
      </React.Fragment>
    ) : (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.fullHeight}
      >
        <Grid item>
          <ErrorOutlineIcon />
        </Grid>
        <Typography variant="overline" display="block">
          error retrieving orders
        </Typography>
      </Grid>
    );
  }
}
