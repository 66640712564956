const assembly = {
  Small: 0,
  Medium: 0,
  Large: 0,
  // Base
  WhiteRice: 0,
  BrownRice: 0,
  SpringMix: 0,
  SuperGreens: 0,

  // Vegetables
  Soybeans: 1,
  KimchiRadish: 1,
  Seaweed: 1,
  CarrotDaikon: 1,
  PurpleCabbage: 1,
  Radish: 1,
  SweetCorn: 1,
  ImitationCrab: 1,
  PineappleSalsa: 1,
  PickledOnion: 1,
  Jalapeno: 1,
  Cucumber: 1,

  // Sauce
  PokeyOkey: 2,
  WasabiMayo: 2,
  SpicySesame: 2,
  SpicyMayo: 2,
  MisoSesame: 2,
  PonzuVinaigrette: 2,
  PonzuDressing: 2,
  SmokeyTeriyaki: 2,
  GarlicAioli: 2,
  ChiliCilantro: 2,
  Bibimbap: 2,

  // Protein
  Ahi: 3,
  Salmon: 3,
  Scallop: 3,
  SpicyAhi: 3,
  SpicySalmon: 3,
  SweetOmelette: 3,
  Tofu: 3,
  CoconutShrimp: 3,
  Octopus: 3,
  LuauPulledPork: 3,
  SambalChicken: 3,
  SmokeySalmon: 3,
  BeefyBeef: 3,
  SpicySquid: 3,
  LomiLomi: 3,
  RedHotChicken: 3,
  JuiceyChicken: 3,

  // Toppings
  FriedGarlic: 4,
  FriedOnions: 4,
  WasabiPeas: 4,
  SeaweedFlakes: 4,
  TempuraFlakes: 4,
  BaconBits: 4,
  Avocado: 4,
  TorchedSalmonBelly: 4,

  // Garnishes
  CapelinRoe: 5,
  FlyingFishRoe: 5,
  GreenOnion: 5,
  Sprouts: 5,
  PickledGinger: 5,
  PokeyYokey: 5,
  RamenNoodles: 5,
  QuailEggs: 5,
  RamenEgg: 5,
};

const getAssembly = (order, skuContext, storeId) => {
  let prepList = order.prep;
  let prepModifiers = order.prepModifiers;
  let stepsList = [];
  let unknowns = [];
  if (prepList.length) {
    let prepSteps = new Array(6);
    for (let k = 0; k < prepSteps.length; k++) {
      prepSteps[k] = [];
    }

    for (let i = 0; i < prepList.length; i++) {
      let skuLookupKey = `${storeId}-${prepList[i]}`;
      if (skuContext[skuLookupKey]) {
        if (
          prepModifiers !== undefined &&
          prepModifiers[prepList[i]] !== undefined
        ) {
          if (skuContext[skuContext[skuLookupKey].sku].assembly <= 5) {
            prepSteps[skuContext[skuContext[skuLookupKey].sku].assembly].push(
              prepList[i] + " - " + prepModifiers[prepList[i]]
            );
          }
        } else {
          if (skuContext[skuContext[skuLookupKey].sku].assembly <= 5) {
            prepSteps[skuContext[skuContext[skuLookupKey].sku].assembly].push(
              prepList[i]
            );
          }
        }
      } else {
        unknowns.push(prepList[i]);
      }
    }

    for (let j = 0; j < prepSteps.length; j++) {
      let step = prepSteps[j];
      let steps = [];
      for (let jj = 0; jj < step.length; jj++) {
        steps.push(step[jj]);
      }
      stepsList.push(steps);
    }
  }
  return {
    steps: stepsList,
    unknowns: unknowns,
  };
};

export default getAssembly;
