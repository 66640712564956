import awsconfig from "../../aws-exports";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import React, { Component } from "react";

import "../../css/AuthStateApp.css";

Amplify.configure(awsconfig);

export class Authenticator extends Component {
  render() {
    return (
      <AmplifyAuthenticator usernameAlias="username">
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="username"
          formFields={[
            {
              type: "username",
              label: "Username",
              placeholder: "Username",
              required: true,
            },
            {
              type: "password",
              label: "Password",
              placeholder: "Password",
              required: true,
            },
            {
              type: "email",
              label: "Email Address",
              placeholder: "Email",
              required: true,
            },
            {
              type: "name",
              label: "Store Name",
              placeholder: "Store Name",
              required: true,
            },
            {
              type: "address",
              label: "Address",
              placeholder: "Address",
              required: true,
            },
          ]}
        />
      </AmplifyAuthenticator>
    );
  }
}

export default Authenticator;
