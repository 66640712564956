import Typography from "@material-ui/core/Typography";
import React from "react";

export default function OrderStatus(props) {
  if (props.status === "0") {
    return <Typography variant="button">Open</Typography>;
  } else if (props.status === "1") {
    return <Typography variant="button">Accepted</Typography>;
  } else {
    return <Typography variant="button">Completed</Typography>;
  }
}
