import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import { useSnackbar } from "notistack";
import React from "react";

const PrintLineItem = ({ comment, items, quantity }) => {
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    let categoryOrder = [];
    let categoryMap = {};
    for (let item of items) {
      if (item.category in categoryMap) {
        categoryMap[item.category].push({
          name: item.name,
          quantity: item.quantity,
          depth: item.depth,
        });
      } else {
        categoryMap[item.category] = [
          {
            name: item.name,
            quantity: item.quantity,
            depth: item.depth,
          },
        ];
        categoryOrder.push(item.category);
      }
    }

    let categories_ = categoryOrder.map((category) => ({
      category: category,
      value: categoryMap[category],
    }));

    setCategories(categories_);

    return () => {
      setCategories([]);
    };
  }, [items]);

  return (
    <React.Fragment>
      {comment.map((item, index) => (
        <React.Fragment key={index}>
          {`${item.label}\n`}
          {`${item.text}\n`}
          {`---------------------\n`}
        </React.Fragment>
      ))}
      {categories.map((item, index) =>
        item.category == "root" ? (
          item.value.map((item, index) => (
            <React.Fragment key={index}>
              {`${item.name}\n`}
              {`${"Quantity: " + quantity}\n\n`}
            </React.Fragment>
          ))
        ) : (
          <React.Fragment key={index}>
            {`  ${item.category}\n`}
            {item.value.map((item, index) =>
              item.quantity > 1
                ? `    ${[...Array(item.depth).keys()].map(() => "  ")}${
                    item.quantity
                  } - ${item.name}\n`
                : `    ${[...Array(item.depth).keys()].map(() => "  ")}${
                    item.name
                  }\n`
            )}
          </React.Fragment>
        )
      )}
      {`---------------------\n`}
    </React.Fragment>
  );
};

const OrderToPrintTextOnly = ({ item, shortId }) => {
  return (
    <pre id={"print-" + item.order_id} style={{ display: "none" }}>
      {`${shortId}\n` +
        `*** Loyalty stamps have been awarded through the app for this order *** \n\n` +
        `${new Date(item.timestamp).toLocaleTimeString()}\n\n` +
        `Pickup Time:  ${
          !item.pickUpTime || item.pickUpTime == "ASAP"
            ? "ASAP"
            : `*** ${item.pickUpTime} ***`
        }\n\n` +
        `${item.customer_name}\n` +
        `${item.customer_email}\n`}
      {`Tip: $${(item.tip / 100).toFixed(2)}\n`}
      {`Bag: ${item.bag ? "Include bag" : "Do not include bag"}\n`}
      {`Utensils: ${
        item.utensils ? "Include utensils" : "Do not include utensils"
      }\n\n`}
      {`*********************\n\n`}
      {item.immutableLineItems.map((lineItem, index) => (
        <PrintLineItem key={index} {...lineItem} />
      ))}
      {`*** End of Order ${shortId} ***\n`}
    </pre>
  );
};

const doRawBTPrint = (el) => {
  let prefix = "intent:";
  let suffix =
    "#Intent;scheme=rawbt;component=ru.a402d.rawbtprinter.activity.PrintDownloadActivity;package=ru.a402d.rawbtprinter;end;";
  let target = document.getElementById(el).innerText;
  let encodedURI = encodeURI(target);
  window.location.href = prefix + encodedURI + suffix;
};

const doNewRawBTPrint = (el) => {
  var S = "#Intent;scheme=rawbt;";
  var P = "package=ru.a402d.rawbtprinter;end;";
  let target = document.getElementById(el).innerText;
  var textEncoded = encodeURI(target);
  window.location.href = "intent:" + textEncoded + S + P;
  console.log("intent:" + textEncoded + S + P);
};

export default function PrintMobileOrderButton({
  item,
  shortId,
  label,
  variant,
  onClick,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleRawBTPrint = async () => {
    try {
      doRawBTPrint("print-" + item.order_id);
      enqueueSnackbar("Printing to bluetooth printer...");
    } catch (err) {
      enqueueSnackbar("Failed to print", {
        variant: "error",
      });
    }
  };

  const handleOnClick = async () => {
    if (onClick) {
      await onClick();
    }
    handleRawBTPrint();
  };

  return (
    <React.Fragment>
      <OrderToPrintTextOnly item={item} shortId={shortId} />
      <Button
        onClick={handleOnClick}
        variant={variant ? variant : "outlined"}
        color="primary"
        startIcon={<PrintIcon />}
      >
        {label ? label : "Print"}
      </Button>
    </React.Fragment>
  );
}
