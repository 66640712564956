import { Badge, Box, Button, Container, Fab, Grid, List, ListItem, Typography, Zoom } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Comment";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { Alert } from "@material-ui/lab";
import React from "react";
import ReactHowler from "react-howler";

import { MetadataContext } from "../../../context/MetadataContext";
import { SKUTableContext } from "../../../context/SKUTableContext";
import alarmUrl from "../../../sound/alarm.mp3";
import changeReqUrl from "../../../sound/change.mp3";
import LegacyOrderAPIClient from "../../api/LegacyOrderAPIClient";

const useStyles = makeStyles((theme) => ({
  scrollTopFab: {
    position: "fixed",
    bottom: 0,
    right: 0,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
  scrollOpenFab: {
    position: "fixed",
    bottom: 0,
    right: 0,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(20),
  },
  scrollUnackFab: {
    position: "fixed",
    bottom: 0,
    left: 0,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
}));

export default function DashboardView() {
  const classes = useStyles();
  const [active, setActive] = React.useState(false);
  const [alarm, setAlarm] = React.useState(false);
  const [showScrollToTopFab, setShowScrollToTopFab] = React.useState(false);
  const [openOrders, setOpenOrders] = React.useState(0);
  const [openOrderRefs, setOpenOrderRefs] = React.useState([]);
  const [unackChangeRefs, setUnackChangeRefs] = React.useState([]);
  const [oosList, setOosList] = React.useState([]);

  const skuContext = React.useContext(SKUTableContext);
  const metadataContext = React.useContext(MetadataContext);

  const handleBegin = () => {
    setActive(true);
  };

  const handleAlarmOn = () => {
    setAlarm(true);
  };

  const handleAlarmOff = () => {
    setAlarm(false);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const onScrollChange = () => {
    setShowScrollToTopFab(window.scrollY > 0);
  };

  const onFocusChange = () => {
    setActive(false);
  };

  const handleScrollToOpenOrder = () => {
    try {
      if (openOrderRefs.length > 0) {
        openOrderRefs[0].ref.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    } catch (err) {}
  };

  const handleScrollToUnackRef = () => {
    try {
      if (unackChangeRefs.length > 0) {
        unackChangeRefs[0].callback();
        unackChangeRefs[0].ref.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        setTimeout(() => {
          unackChangeRefs[0].ref.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 500);
      }
    } catch (err) {}
  };

  const handleUpdateOpenOrders = (value) => {
    setOpenOrders(value);
  };

  const handleRegisterRef = (id, ref) => {
    setOpenOrderRefs((openOrderRefs) =>
      openOrderRefs.concat({ id: id, ref: ref })
    );
  };

  const handleUnregisterRef = (id) => {
    setOpenOrderRefs((openOrderRefs) =>
      openOrderRefs.filter((item) => id != item.id)
    );
  };

  const handleRegisterUnackRef = (id, ref, cb) => {
    setUnackChangeRefs((unackChangeRefs) =>
      unackChangeRefs.concat({ id: id, ref: ref, callback: cb })
    );
  };

  const handleUnregisterUnackRef = (id) => {
    setUnackChangeRefs((unackChangeRefs) =>
      unackChangeRefs.filter((item) => id != item.id)
    );
  };

  React.useEffect(() => {
    window.addEventListener("visibilitychange", onFocusChange, false);
    window.addEventListener("pagehide", onFocusChange, false);
    window.addEventListener("scroll", onScrollChange);

    return () => {
      window.removeEventListener("visibilitychange", onFocusChange, false);
      window.removeEventListener("pagehide", onFocusChange, false);
      window.addEventListener("scroll", onScrollChange);
    };
  }, []);

  React.useEffect(() => {
    if (skuContext && metadataContext) {
      setOosList(
        Object.values(skuContext).filter(
          (item) =>
            item.active === false && item.store === metadataContext.storeId
        )
      );
    }
  }, [skuContext, metadataContext]);

  return (
    <React.Fragment>
      {active ? (
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <LegacyOrderAPIClient
                handleAlarmOn={handleAlarmOn}
                handleAlarmOff={handleAlarmOff}
                handleUpdateOpenOrders={handleUpdateOpenOrders}
                registerRef={handleRegisterRef}
                unregisterRef={handleUnregisterRef}
                registerUnackRef={handleRegisterUnackRef}
                unregisterUnackRef={handleUnregisterUnackRef}
                alarmState={alarm}
              />
            </Grid>
            <Zoom in={showScrollToTopFab} unmountOnExit>
              <Fab
                color="secondary"
                aria-label="scrollTop"
                onClick={handleScrollToTop}
                className={classes.scrollTopFab}
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </Zoom>
            <Zoom in={openOrders > 0} unmountOnExit>
              <Fab
                color="secondary"
                aria-label="scrollToOpenOrder"
                onClick={handleScrollToOpenOrder}
                className={classes.scrollOpenFab}
              >
                <Badge
                  badgeContent={openOrders}
                  color="error"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <NotificationsActiveIcon />
                </Badge>
              </Fab>
            </Zoom>
            <Zoom in={unackChangeRefs.length > 0} unmountOnExit>
              <Fab
                color="secondary"
                aria-label="scrollToOpenOrder"
                onClick={handleScrollToUnackRef}
                className={classes.scrollUnackFab}
              >
                <Badge
                  variant="dot"
                  color="error"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <CommentIcon />
                </Badge>
              </Fab>
            </Zoom>
          </Container>
        </React.Fragment>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          width={"100%"}
          height={"80vh"}
        >
          {oosList.length > 0 ? (
            <Alert severity="info">
              Please note the following items are currently set to out of stock:
              <List>
                {oosList.map((item, index) => {
                  return (
                    <ListItem key={index} dense disableGutters>
                      <Typography variant="caption">
                        {`${item.categoryId} / ${item.name}`}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Alert>
          ) : null}
          <Box m="auto">
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="overline" gutterBottom>
                Tap to begin accepting orders
              </Typography>
              <Button
                onClick={handleBegin}
                variant="contained"
                color="primary"
                size="large"
              >
                Begin
              </Button>
            </Grid>
          </Box>
        </Box>
      )}
      <ReactHowler src={alarmUrl} playing={active && alarm} loop={true} />
      <ReactHowler
        src={changeReqUrl}
        playing={active && unackChangeRefs.length > 0}
        loop={true}
      />
    </React.Fragment>
  );
}
