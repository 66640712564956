import {
  Button,
  Card,
  CardContent,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  card: {
    margin: theme.spacing(2),
  },
}));

function QuickViewItem({ comment, items, quantity }) {
  const classes = useStyles();

  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    let categoryOrder = [];
    let categoryMap = {};
    for (let item of items) {
      if (item.category in categoryMap) {
        categoryMap[item.category].push({
          name: item.name,
          quantity: item.quantity,
          depth: item.depth,
        });
      } else {
        categoryMap[item.category] = [
          {
            name: item.name,
            quantity: item.quantity,
            depth: item.depth,
          },
        ];
        categoryOrder.push(item.category);
      }
    }

    let categories_ = categoryOrder.map((category) => ({
      category: category,
      value: categoryMap[category],
    }));

    setCategories(categories_);

    return () => {
      setCategories([]);
    };
  }, [items]);

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        {comment.map((item, index) => (
          <ListItem key={index} disableGutters={true}>
            <ListItemText
              primary={`${item.label}`}
              secondary={`"${item.text}"`}
            />
          </ListItem>
        ))}
        {categories.map((item, index) =>
          item.category == "root" ? (
            item.value.map((item, index) => (
              <ListItem disableGutters={true}>
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{ variant: "h6" }}
                  secondary={"Quantity: " + quantity}
                />
              </ListItem>
            ))
          ) : (
            <React.Fragment key={index}>
              <ListItem disableGutters={true}>
                <ListItemText primary={item.category} />
              </ListItem>
              {item.value.map((item, index) => (
                <ListItem
                  key={index}
                  className={classes.nested}
                  disableGutters={true}
                >
                  <ListItemText
                    style={{ marginLeft: 16 * item.depth }}
                    disableGutters={true}
                    secondary={
                      item.quantity > 1
                        ? `${item.quantity} - ${item.name}`
                        : item.name
                    }
                  />
                </ListItem>
              ))}
            </React.Fragment>
          )
        )}
      </CardContent>
    </Card>
  );
}

export default function QuickViewDialog({ shortId, item, open, handleClose }) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      aria-labelledby="quick-view-dialog-title"
      open={open}
    >
      <DialogTitle id="quick-view-dialog-title" onClose={handleClose}>
        {shortId}
      </DialogTitle>
      <DialogContent dividers>
        <ListItem disableGutters={true}>
          <ListItemText
            primary="Bag"
            secondary={item.bag ? "Include bag" : "Do not include bag"}
          />
        </ListItem>
        <ListItem disableGutters={true}>
          <ListItemText
            primary="Utensils"
            secondary={
              item.utensils ? "Include utensils" : "Do not include utensils"
            }
          />
        </ListItem>
        {item.immutableLineItems.map((lineItem, index) => (
          <QuickViewItem key={index} {...lineItem} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
