import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

export default function ConfirmMarkCompleteDialog(props) {
  const handleMarkComplete = () => {
    props.handleMarkComplete(props.id);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Mark Order " +
          props.id.substring(0, 8).toUpperCase() +
          " as complete"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will mark the order as complete but not send any notifications to
          the customer. Do you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleMarkComplete}
          color="primary"
          autoFocus
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}
