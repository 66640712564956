import { Chip, Collapse, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneIcon from "@material-ui/icons/Done";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import UndoIcon from "@material-ui/icons/Undo";
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";

import { AuthContext } from "../../../../context/AuthContext";
import mobileOrderConfig from "../../../../mobileOrderConfig";
import PrintMobileOrderButton from "../../button/PrintMobileOrderButton";
import ConfirmMarkCompleteDialog from "../ConfirmMarkCompleteDialog";
import NotifyDelayDialog from "../NotifyDelayDialog";
import OrderStatusText from "../OrderStatusText";
import RefundDialog from "../RefundDialog";
import QuickViewDialog from "./QuickViewDialog";
import TransferOrderDialog from "./TransferOrderDialog";

const stalenessGradient = [
  "#FFFFFF",
  "#FFFFFF",
  "#FFF7EB",
  "#FFEFD7",
  "#FFE7C4",
  "#FFDFB0",
  "#FFD79C",
  "#FFCF88",
  "#FFC775",
  "#FFBF61",
  "#FFB74D",
  "#FCAF51",
  "#F9A855",
  "#F6A05A",
  "#F3995E",
  "#F19162",
  "#EE8A66",
  "#EB826B",
  "#E87B6F",
  "#E57373",
];

const earlinessGradient = [
  "#FFFFFF",
  "#EEF7FE",
  "#DDEFFD",
  "#CBE6FC",
  "#BADEFB",
  "#A9D6FA",
  "#98CEF9",
  "#86C5F8",
  "#75BDF7",
  "#64B5F6",
];

const calcStaleness = (timestamp, pickUpTime) => {
  const MAX_STALENESS_IN_MILLIS = 60 * 60 * 1000; // 30 minutes
  let currentTime = new Date();
  let orderTime = new Date(timestamp);
  if (currentTime.getDate() === orderTime.getDate()) {
    // Same day
    if (pickUpTime && pickUpTime !== "ASAP") {
      let magnitude =
        currentTime.getTime() -
        Date.parse(new Date().toDateString() + " " + pickUpTime);
      let sign = magnitude > 0 ? 1 : -1;
      return (
        sign *
        Math.min(Math.abs((magnitude * 100) / MAX_STALENESS_IN_MILLIS), 100)
      ).toFixed();
    } else {
      return Math.min(
        ((currentTime.getTime() - orderTime) * 100) / MAX_STALENESS_IN_MILLIS,
        100
      ).toFixed();
    }
  }
  return 100;
};

const getCardStyle = (staleness, orderStatus, theme) => {
  if (orderStatus == "in_progress") {
    let color = "#ffffff";
    if (staleness > 0) {
      if (staleness == 100) {
        color = theme.palette.error.light;
      } else {
        color = stalenessGradient[Math.floor(staleness / 5)];
      }
    } else {
      if (staleness == -100) {
        color = theme.palette.info.light;
      } else if (staleness < -50) {
        color =
          earlinessGradient[Math.floor(Math.abs(parseInt(staleness) + 50) / 5)];
      }
    }
    return {
      backgroundColor: color,
    };
  } else {
    return {
      backgroundColor: theme.palette.success.light,
    };
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    transition: "background-color 1s ease",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      width: "120px",
    },
  },
  spacing: {
    margin: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: 32,
  },
  animatedItem: {
    animation: `$flash 1s infinite`,
  },
  "@keyframes flash": {
    "0%": {
      backgroundColor: "#ff9900",
    },
    "80%": {
      backgroundColor: "#ffffff",
    },
    "100%": {
      backgroundColor: "#ff9900",
    },
  },
  cardActions: {
    flexDirection: "column",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  confirmColor: {
    color: theme.palette.success.dark,
  },
  rejectColor: {
    color: theme.palette.error.dark,
  },
  listItemText: {
    maxWidth: "70%",
  },
  changeRequestSection: {
    backgroundColor: "white",
  },
  dialogSize: {
    flex: 1,
    margin: 16,
  },
}));

const getTimestring = (timestamp) => {
  return new Date(timestamp).toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
};

export default function OrderCard({
  item,
  onHandleSync,
  registerRef,
  unregisterRef,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const authContext = React.useContext(AuthContext);
  const [quickViewOpen, setQuickViewOpen] = React.useState(false);
  const [transferOrderOpen, setTransferOrderOpen] = React.useState(false);
  const [markCompleteOpen, setMarkCompleteOpen] = React.useState(false);
  const [notifyDelayOpen, setNotifyDelayOpen] = React.useState(false);
  const [refundOpen, setRefundOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const self = React.useRef(null);
  const changesRef = React.useRef(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleQuickViewOpen = () => {
    setQuickViewOpen(true);
    handleMenuClose();
  };

  const handleQuickViewClose = () => {
    setQuickViewOpen(false);
  };

  const handleTransferOrderOpen = () => {
    setTransferOrderOpen(true);
    handleMenuClose();
  };

  const handleTransferOrderClose = () => {
    setTransferOrderOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSync = (mutatedItem) => {
    onHandleSync(item.order_id, mutatedItem);
  };

  const handleAccept = async () => {
    let mutatedItem = JSON.parse(JSON.stringify(item));
    mutatedItem.status = "in_progress";
    handleSync(mutatedItem);
    try {
      await axios.get(mobileOrderConfig.endpoint, {
        params: {
          key: mobileOrderConfig.key,
          type: "accept",
          user_id: item.user_id,
          order_id: item.order_id,
        },
      });
      enqueueSnackbar(`${shortId} accepted`, {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Failed to accept ${shortId}`, {
        variant: "error",
      });
    }
  };

  const handleComplete = async (id) => {
    let mutatedItem = JSON.parse(JSON.stringify(item));
    mutatedItem.status = "completed";
    handleSync(mutatedItem);
    try {
      await axios.get(mobileOrderConfig.endpoint, {
        params: {
          key: mobileOrderConfig.key,
          type: "complete",
          user_id: item.user_id,
          order_id: item.order_id,
        },
      });
      enqueueSnackbar(`${shortId} completed`, {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Failed to complete ${shortId}`, {
        variant: "error",
      });
    }
  };

  const handleTransfer = async (targetStoreId) => {
    let mutatedItem = null;
    handleSync(mutatedItem);
    handleTransferOrderClose();
    try {
      await axios.get(mobileOrderConfig.endpoint, {
        params: {
          key: mobileOrderConfig.key,
          type: "transfer",
          user_id: item.user_id,
          order_id: item.order_id,
          store_id: targetStoreId,
        },
      });
      enqueueSnackbar(`${shortId} transferred`, {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Failed to transfer ${shortId}`, {
        variant: "error",
      });
    }
  };

  const [cardStyle, setCardStyle] = React.useState();
  const [timeString, setTimeString] = React.useState();
  const [shortId, setShortId] = React.useState();

  React.useEffect(() => {
    setTimeString(getTimestring(item.timestamp));
    setCardStyle(
      getCardStyle(
        calcStaleness(item.timestamp, item.pickUpTime),
        item.status,
        theme
      )
    );
  }, [item.timestamp, item.pickUpTime, item.status]);

  React.useEffect(() => {
    setShortId(`Mobile Order ${item.order_id.substring(0, 5).toUpperCase()}`);
  }, [item.order_id]);

  React.useEffect(() => {
    if (item.status == "created") {
      registerRef(item.order_id, self);
    } else {
      unregisterRef(item.order_id);
    }
  }, [item.status, item.order_id]);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card
        className={`${classes.root} ${
          item.status == "created" ? classes.animatedItem : ""
        }`}
        style={cardStyle}
        ref={self}
      >
        <CardHeader
          action={
            <IconButton aria-label="menu" onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
          }
          title={shortId}
          subheader={timeString}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleQuickViewOpen}>
            <ListItemIcon className={classes.listItemIcon}>
              <SearchIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Quick View</Typography>
          </MenuItem>
          <MenuItem onClick={handleTransferOrderOpen}>
            <ListItemIcon className={classes.listItemIcon}>
              <SwapHorizIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Transfer</Typography>
          </MenuItem>
        </Menu>
        <QuickViewDialog
          open={quickViewOpen}
          handleClose={handleQuickViewClose}
          shortId={shortId}
          item={item}
        />
        <TransferOrderDialog
          open={transferOrderOpen}
          onHandleTransfer={handleTransfer}
          onHandleClose={handleTransferOrderClose}
          shortId={shortId}
        />
        <CardContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography variant="h6" color="textPrimary">
              {item.customer_name}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {item.customer_email}
            </Typography>
            <Typography variant="overline" color="textSecondary">
              {"Pickup Time: " + (item.pickUpTime ? item.pickUpTime : "ASAP")}
            </Typography>
            {item.status == "created" ? (
              <Typography variant="button">Open</Typography>
            ) : item.status == "in_progress" ? (
              <Typography variant="button">In Progress</Typography>
            ) : (
              <Typography variant="button">Completed</Typography>
            )}
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.buttons}
          >
            {item.status == "created" ? (
              <PrintMobileOrderButton
                item={item}
                shortId={shortId}
                onClick={handleAccept}
                variant="contained"
                label="Accept"
              />
            ) : item.status == "in_progress" ? (
              <React.Fragment>
                <PrintMobileOrderButton item={item} shortId={shortId} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleComplete}
                >
                  Complete
                </Button>
              </React.Fragment>
            ) : (
              <PrintMobileOrderButton item={item} shortId={shortId} />
            )}
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}
