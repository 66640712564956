import { API, graphqlOperation } from "aws-amplify";
import React from "react";

import { MetadataContext } from "../../context/MetadataContext";
import { SKUTableContext } from "../../context/SKUTableContext";

// const getTimestamp

export default function SKUTableAPIClient(props) {
  const metadataContext = React.useContext(MetadataContext);
  const [skuTable, setSkuTable] = React.useState();

  const doFetch = () => {
    const fetchCatalog = async () => {
      try {
        let affiliated = metadataContext.affiliateStoreIds;
        let storeIdList = [...affiliated].concat(metadataContext.storeId);

        let table = {};
        for (let storeId of storeIdList) {
          const graphqlQuery = `query listCatalogQuery {
            listCatalogItems(STORE: "${storeId}") {
              nextToken
              items {
                PRODUCT_CATEGORY_ID
                PRODUCT_CATEGORY_NAME
                PRODUCT_ID
                PRODUCT_NAME
                PRICE
                SKU
                ASSEMBLY
                ACTIVE
              }
            }
          }          
          `;

          let graphqlQueryResult = await API.graphql(
            graphqlOperation(graphqlQuery)
          );
          if (graphqlQueryResult) {
            let items = graphqlQueryResult.data.listCatalogItems.items;

            for (let item of items) {
              table[item.SKU] = {
                categoryId: item.PRODUCT_CATEGORY_ID,
                categoryName: item.PRODUCT_CATEGORY_NAME,
                id: item.PRODUCT_ID,
                price: item.PRICE,
                name: item.PRODUCT_NAME,
                active: item.ACTIVE,
                assembly: item.ASSEMBLY,
                store: storeId,
              };
              let skuLookupKey = `${storeId}-${item.PRODUCT_ID}`;
              table[skuLookupKey] = {
                sku: item.SKU,
              };
            }
          }
        }
        setSkuTable(table);
      } catch (err) {
        console.error(err);
      }
    };
    if (metadataContext) {
      fetchCatalog();
    }
  };

  React.useEffect(doFetch, [metadataContext]);

  return (
    <SKUTableContext.Provider value={skuTable}>
      {props.children}
    </SKUTableContext.Provider>
  );
}
