import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
  },
}));

const DangerButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.error.dark),
    backgroundColor: theme.palette.error.dark,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
}))(Button);

export default function OrderControlDialog(props) {
  const classes = useStyles();
  const [reason, setReason] = React.useState(null);

  const handleDisableOrdering = () => {
    props.handleDisableOrdering(false, reason);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleClose = () => {
    setReason(null);
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Temporarily disable ${props.store} online ordering`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Enter the reason for disabling online ordering. This message is shown
          to customers as long as ordering is disabled.
        </DialogContentText>
        <Grid item>
          <TextField
            className={classes.field}
            label="Reason (required)"
            variant="outlined"
            onChange={handleReasonChange}
            placeholder="eg. Maintenance"
            multiline
            rows={3}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <DangerButton
          variant="contained"
          onClick={handleDisableOrdering}
          color="primary"
          disabled={reason == null}
          autoFocus
        >
          Disable
        </DangerButton>
      </DialogActions>
    </Dialog>
  );
}
