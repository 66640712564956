import DateFnsUtils from "@date-io/date-fns";
import { Grid, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PrintIcon from "@material-ui/icons/Print";
import ScheduleIcon from "@material-ui/icons/Schedule";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { isAndroid } from "react-device-detect";
import ReactHowler from "react-howler";

import alarmUrl from "../../../sound/alarm.mp3";

const createSetting = (name, description, icon, action) => {
  return {
    name: name,
    description: description,
    icon: icon,
    action: action,
  };
};

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    maxWidth: "130px",
  },
  listItem: {
    maxWidth: "calc(100% - 100px)",
  },
  secondaryAction: { right: 0 },
}));

export default function SettingsView() {
  const classes = useStyles();

  const [printOnAccept, setPrintOnAccept] = React.useState(
    localStorage.getItem("Settings.printOnAccept") === "true" ? true : false
  );

  React.useEffect(() => {
    localStorage.setItem("Settings.printOnAccept", printOnAccept);
  }, [printOnAccept]);

  const onPrintOnAcceptChange = () => {
    setPrintOnAccept(!printOnAccept);
  };

  const [testPlaying, setTestPlaying] = React.useState(false);

  const handleTestPlay = () => {
    setTestPlaying(!testPlaying);
  };

  const handleDateChange = (date) => {
    setFetchTime(date.getHours());
  };

  const [fetchTime, setFetchTime] = React.useState(
    localStorage.getItem("Settings.fetchTime")
      ? localStorage.getItem("Settings.fetchTime")
      : "20"
  );

  React.useEffect(() => {
    localStorage.setItem("Settings.fetchTime", fetchTime);
  }, [fetchTime]);

  const [oneTapPrint, setOneTapPrint] = React.useState(
    localStorage.getItem("Settings.oneTapPrint") === "true" ? true : false
  );

  React.useEffect(() => {
    localStorage.setItem("Settings.oneTapPrint", oneTapPrint);
  }, [oneTapPrint]);

  const onOneTapPrintChange = () => {
    setOneTapPrint(!oneTapPrint);
  };

  const settingsList = [
    createSetting(
      "Last Close",
      "Dashboard will limit shown orders to yesterday's last close time",
      <ScheduleIcon />,
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            InputProps={{ classes: { root: classes.maxWidth } }}
            classes={{ root: classes.maxWidth }}
            label="Yesterday at:"
            value={new Date(new Date().setHours(fetchTime)).setMinutes(0)}
            onChange={handleDateChange}
            autoOk={true}
            ampm={false}
            disableToolbar={true}
            variant="inline"
            inputVariant="outlined"
            views={["hours"]}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    ),
    createSetting(
      "Enable auto-print on accept",
      "Tapping accept will automatically print",
      <PrintIcon />,
      <Switch
        checked={printOnAccept}
        onChange={onPrintOnAcceptChange}
        name="print-setting"
        color="primary"
      />
    ),
    createSetting(
      "Enable One-Tap Print",
      "Requires Android device and RawBT to be installed. Automatically uses thermal printer styling.",
      <PrintIcon />,
      <Switch
        disabled={!isAndroid}
        checked={oneTapPrint}
        onChange={onOneTapPrintChange}
        name="print-setting"
        color="primary"
      />
    ),
    createSetting(
      "Test Notification Sound",
      "Test the incomining order notification sound",
      <NotificationsActiveIcon />,
      <IconButton onClick={handleTestPlay}>
        {testPlaying ? (
          <React.Fragment>
            <VolumeUpIcon />
            <ReactHowler src={alarmUrl} playing={true} loop={true} />
          </React.Fragment>
        ) : (
          <VolumeMuteIcon />
        )}
      </IconButton>
    ),
  ];

  return (
    <List>
      {settingsList.map((setting, index) => (
        <React.Fragment key={index}>
          <ListItem
            alignItems="flex-start"
            className={classes.listItem}
            disableGutters={true}
          >
            <ListItemAvatar>
              <Avatar>{setting.icon}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={setting.name}
              secondary={setting.description}
            />
            <ListItemSecondaryAction
              children={setting.action}
              className={classes.secondaryAction}
            />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
