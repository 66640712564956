import {
  Button,
  Container,
  Dialog,
  DialogContentText,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { MetadataContext } from "../../../context/MetadataContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function TransferOrderDialog(props) {
  const classes = useStyles();
  const metadataContext = React.useContext(MetadataContext);
  const [value, setValue] = React.useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleTransfer = () => {
    props.handleTransfer(props.id, value);
  };

  const handleClose = () => {
    setValue(null);
    props.handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DialogTitle id="transfer-dialog-title" onClose={props.handleClose}>
        {"Transfer Order " + props.id.substring(0, 8).toUpperCase()}
      </DialogTitle>
      <DialogContent>
        <Container>
          <FormControl component="fieldset" className={classes.root}>
            <FormLabel component="legend">
              Select an affiliated store:
            </FormLabel>
            <RadioGroup name="store" onChange={handleChange}>
              {metadataContext && metadataContext.affiliateStoreIds
                ? metadataContext.affiliateStoreIds.map((store, index) => (
                    <FormControlLabel
                      key={index}
                      value={store}
                      control={<Radio />}
                      label={store}
                    />
                  ))
                : null}
            </RadioGroup>
          </FormControl>
          <DialogContentText>
            This order will be transferred to the selected store as an open
            order and removed from this store's queue.
          </DialogContentText>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleTransfer}
          color="primary"
          disabled={value == null}
        >
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
