import {
  Avatar,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContentText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React from "react";

import { SKUTableContext } from "../../../context/SKUTableContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  field: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
  quantityLabel: {
    margin: "auto",
    padding: theme.spacing(2),
  },
  listItem: {
    maxWidth: "50%",
  },
}));

const QuantitySelector = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleRemove = () => {
    if (value > 0) {
      setValue(value - 1);
      props.handleRemove(props.sku);
    }
  };

  const handleAdd = () => {
    if (value < props.max) {
      setValue(value + 1);
      props.handleAdd(props.sku);
    }
  };

  return (
    <Grid container>
      <IconButton onClick={handleRemove} size="small">
        <RemoveCircleIcon color="secondary" />
      </IconButton>
      <Typography variant="h6" className={classes.quantityLabel}>
        {value}
      </Typography>
      <IconButton onClick={handleAdd} size="small">
        <AddCircleIcon color="secondary" />
      </IconButton>
    </Grid>
  );
};

export default function RefundDialog(props) {
  const classes = useStyles();
  const [reason, setReason] = React.useState(null);
  const [employee, setEmployee] = React.useState(null);
  const [skus, setSkus] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const skuContext = React.useContext(SKUTableContext);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleEmployeeChange = (event) => {
    setEmployee(event.target.value);
  };

  const handleAdd = (sku) => {
    const mutated = [...selected];

    mutated.push(sku);

    setSelected(mutated);
    updateTotal(mutated);
  };

  const handleRemove = (sku) => {
    const currentIndex = selected.indexOf(sku);
    const mutated = [...selected];

    mutated.splice(currentIndex, 1);

    setSelected(mutated);
    updateTotal(mutated);
  };

  const handleRefund = () => {
    let refundedItems = selected.map((sku) => {
      return skuContext[sku].name;
    });

    props.handleRefund(props.id, total, employee, reason, refundedItems);
  };

  const updateTotal = (selected) => {
    let amount = 0;
    for (let sku of selected) {
      amount += skuContext[sku].price;
    }
    setTotal(amount);
  };

  const handleClose = () => {
    setSelected([]);
    setEmployee(null);
    setReason(null);
    props.handleClose();
  };

  const refundableItemsFilter = (skuObject) => {
    let allowedCategories = [
      "buildExtraProtein",
      "signatureAddOn",
      "buildExtraVegetables",
      "buildPremium",
      "drinks",
      "sides",
      "buildSides",
      "signatureSides",
    ];
    if (skuObject) {
      return allowedCategories.includes(skuObject.categoryId);
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    if (!props.order || !skuContext) {
      return;
    }
    let skuMap = {};
    let orders = JSON.parse(props.order);
    for (let order of orders) {
      for (let sku of order.skus) {
        if (refundableItemsFilter(skuContext[sku])) {
          if (sku in skuMap) {
            skuMap[sku] += 1;
          } else {
            skuMap[sku] = 1;
          }
        }
      }
    }

    let mutated = [];
    for (let key of Object.keys(skuMap)) {
      mutated.push({ sku: key, count: skuMap[key] });
    }

    setSkus(mutated);
  }, [skuContext, props.order]);

  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DialogTitle id="transfer-dialog-title" onClose={props.handleClose}>
        {`Issue Refund for Order ${props.id.substring(0, 8).toUpperCase()}`}
      </DialogTitle>
      <DialogContent>
        <Container>
          {skus.length > 0 ? (
            <React.Fragment>
              <DialogContentText>
                Select from the list of items below to refund:
              </DialogContentText>
              <List dense>
                {skus.map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        className={classes.listItem}
                        primary={`${skuContext[item.sku].name}`}
                        secondary={`$${(
                          skuContext[item.sku].price / 100
                        ).toFixed(2)}`}
                      />
                      <ListItemSecondaryAction>
                        <QuantitySelector
                          max={item.count}
                          handleAdd={handleAdd}
                          handleRemove={handleRemove}
                          sku={item.sku}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Grid item>
                <Typography
                  variant="caption"
                  gutterBottom
                >{`Amount to be refunded: $${(total / 100).toFixed(
                  2
                )}`}</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={classes.field}
                  label="Employee Name"
                  variant="outlined"
                  onChange={handleEmployeeChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.field}
                  label="Refund reason"
                  variant="outlined"
                  onChange={handleReasonChange}
                  placeholder="eg. Missing topping"
                  multiline
                  rows={2}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <DialogContentText>
              Items in this order are ineligible for refund through the
              restaurant portal. Please contact management for assistance with
              refunding this order.
            </DialogContentText>
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleRefund}
          color="primary"
          disabled={
            reason == null ||
            selected.length == 0 ||
            employee == null ||
            skuContext == null
          }
        >
          Refund
        </Button>
      </DialogActions>
    </Dialog>
  );
}
