import {
  Chip,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import { API, graphqlOperation } from "aws-amplify";
import { useSnackbar } from "notistack";
import React from "react";

import { MetadataContext } from "../../../context/MetadataContext";

const useStyles = makeStyles((theme) => ({
  secondaryAction: { right: 0 },
  filterField: {
    width: "100%",
  },
}));

const compareCategory = (a, b) => {
  return a.PRODUCT_CATEGORY_ID.localeCompare(b.PRODUCT_CATEGORY_ID);
};

const compareIndex = (a, b) => {
  let a_ = a.INDEX ? a.INDEX : 0;
  let b_ = b.INDEX ? b.INDEX : 0;
  return a_ - b_;
};

export default function StoreView() {
  const classes = useStyles();
  const theme = useTheme();

  const metadataContext = React.useContext(MetadataContext);
  const [filter, setFilter] = React.useState("");
  const [catalogData, setCatalogData] = React.useState([]);
  const [responseStatus, setResponseStatus] = React.useState();
  const [toggleSameName, setToggleSameName] = React.useState(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleToggleSameName = (event) => {
    setToggleSameName(!toggleSameName);
  };

  const handleSwitch = (event) => {
    let updatedCatalogData = [...catalogData];
    if (toggleSameName) {
      updatedCatalogData = updateAllWithSameName(
        updatedCatalogData[event.target.dataset.index].PRODUCT_NAME,
        !updatedCatalogData[event.target.dataset.index].ACTIVE
      );
    } else {
      updatedCatalogData[
        event.target.dataset.index
      ].ACTIVE = !updatedCatalogData[event.target.dataset.index].ACTIVE;
      updateSKU(
        event.target.dataset.sku,
        event.target.dataset.index,
        updatedCatalogData[event.target.dataset.index].ACTIVE
      );
    }
    setCatalogData(updatedCatalogData);
  };

  const updateAllWithSameName = (itemName, state) => {
    let updatedCatalogData = [...catalogData];
    for (let [index, item] of updatedCatalogData.entries()) {
      if (item.PRODUCT_NAME == itemName) {
        item.ACTIVE = state;
        updateSKU(item.SKU, index, state);
      }
    }
    return updatedCatalogData;
  };

  const updateSKU = async (sku, index, active) => {
    try {
      let storeId = metadataContext.storeId;

      const graphqlMutation = `mutation updateCatalogItem {
        updateCatalogItem(input: {ACTIVE: ${active}, SKU: "${sku}", STORE: "${storeId}"}) {
          ACTIVE
        }
      }
      `;

      await API.graphql(graphqlOperation(graphqlMutation));
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Error updating SKU: ${sku}`, {
        variant: "error",
        preventDuplicate: false,
      });
      let updatedCatalogData = [...catalogData];
      updatedCatalogData[index].ACTIVE = !active;
      setCatalogData(updatedCatalogData);
    }
  };

  const doFetch = () => {
    const fetchCatalog = async () => {
      try {
        let storeId = metadataContext.storeId;

        const graphqlQuery = `query listCatalogQuery {
            listCatalogItems(STORE: "${storeId}") {
              nextToken
              items {
                PRODUCT_CATEGORY_ID
                PRODUCT_CATEGORY_NAME
                METADATA
                IMAGE_URL
                PRICE
                PRODUCT_ID
                PRODUCT_NAME
                ACTIVE
                CHOICES
                SKU
                STORE
                INDEX
                DESCRIPTION
                TAG
              }
            }
          }          
          `;

        let graphqlQueryResult = await API.graphql(
          graphqlOperation(graphqlQuery)
        );
        if (graphqlQueryResult) {
          let items = graphqlQueryResult.data.listCatalogItems.items;
          items.sort(compareIndex);
          items.sort(compareCategory);
          setResponseStatus(200);

          setCatalogData(items);
        }
      } catch (err) {
        setResponseStatus(503);
        setCatalogData([]);
        console.error(err);
      }
    };
    if (metadataContext) {
      fetchCatalog();
    }
  };

  React.useEffect(doFetch, [metadataContext]);

  return (
    <React.Fragment>
      <Grid container>
        <Typography variant="overline" gutterBottom style={{ flexGrow: 1 }}>
          Toggle same item name
        </Typography>
        <Switch
          checked={toggleSameName}
          onChange={handleToggleSameName}
          size="small"
        />
      </Grid>
      <TextField
        className={classes.filterField}
        label="Filter"
        onChange={handleFilterChange}
        placeholder="Item Name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FilterListIcon />
            </InputAdornment>
          ),
        }}
      />
      <List className={classes.list}>
        {responseStatus == 200 ? (
          catalogData.map((item, index) => (
            <React.Fragment key={item.SKU}>
              {item.PRODUCT_NAME.toLowerCase().includes(
                filter.toLowerCase()
              ) ? (
                <React.Fragment>
                  <ListItem alignItems="flex-start" disableGutters={true}>
                    <ListItemText
                      disableTypography={true}
                      primary={
                        <Typography gutterBottom>
                          {`${item.PRODUCT_CATEGORY_ID} / ${item.PRODUCT_NAME}`}
                        </Typography>
                      }
                      secondary={
                        <Chip
                          style={{
                            backgroundColor: item.ACTIVE
                              ? theme.palette.success.light
                              : theme.palette.error.light,
                          }}
                          label={
                            <Typography variant="overline">
                              {item.ACTIVE ? "In stock" : "Out of stock"}
                            </Typography>
                          }
                        ></Chip>
                      }
                    />

                    <ListItemSecondaryAction
                      className={classes.secondaryAction}
                    >
                      <Switch
                        checked={item.ACTIVE}
                        inputProps={{
                          "data-sku": item.SKU,
                          "data-index": index,
                        }}
                        onChange={handleSwitch}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ))
        ) : (
          <LinearProgress color="secondary" />
        )}
      </List>
    </React.Fragment>
  );
}
