const prefixCategoryMap = {
  buildBase: "Base",
  buildProtein: "Protein",
  buildExtraProtein: "Extra Protein",
  buildVegetable: "Vegetables",
  buildExtraVegetables: "Extra Vegetables",
  buildSauce: "Sauce",
  buildDry: "Dry Toppings",
  buildPremium: "Premium Toppings",
  signatureSize: "Size",
  signatureBase: "Base",
  signatureAddOn: "Add On",
  signatureAddExtra: "Add Extra",
  comment: "Comments",
  signatureSides: "Side Dishes",
  buildSides: "Side Dishes",
};

const getSummary = (order) => {
  let summary = [];
  for (let item of JSON.parse(order)) {
    let detailsList = [];
    if (item.details) {
      let detailKeys = Object.keys(item.details);

      for (let j = 0; j < detailKeys.length; j++) {
        let keyName = prefixCategoryMap[detailKeys[j]];
        let detailValues = item.details[detailKeys[j]];

        let detail = {
          key: keyName,
          items: detailValues,
        };
        detailsList.push(detail);
      }
    }

    summary.push({
      quantity: item.quantity,
      itemName: item.name,
      details: detailsList,
    });
  }

  return summary;
};

export default getSummary;
