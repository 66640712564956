import { Button, Card, CardContent, Dialog, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import getSummary from "../../../util/legacy/details";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  card: {
    margin: theme.spacing(2),
  },
}));

function QuickViewItem(props) {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        <Typography variant="body1">{props.item.itemName}</Typography>
        <Typography variant="subtitle2">
          {"Quantity: " + props.item.quantity}
        </Typography>
        {props.item.details.length > 0 ? (
          <List className={classes.root} dense={true}>
            {props.item.details.map((detail, index) => (
              <React.Fragment key={index}>
                <ListItem disableGutters={true}>
                  <ListItemText primary={detail.key} />
                </ListItem>
                {detail.items.map((details, index) => (
                  <ListItem
                    key={index}
                    details={details}
                    className={classes.nested}
                    disableGutters={true}
                  >
                    <ListItemText secondary={details} />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default function QuickViewDialog(props) {
  const classes = useStyles();

  const [summary, setSummary] = React.useState();

  React.useEffect(() => {
    setSummary(getSummary(props.item));
  }, [props.item]);

  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="quick-view-dialog-title"
      open={props.open}
    >
      <DialogTitle id="quick-view-dialog-title" onClose={props.handleClose}>
        {"Order " + props.id}
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">
          {props.comment ? "Comment: " : null}
        </Typography>
        <Typography variant="body1" className={classes.nested}>
          {props.comment ? props.comment : null}
        </Typography>
        {summary
          ? summary.map((item, index) => (
              <QuickViewItem key={index} item={item}></QuickViewItem>
            ))
          : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
