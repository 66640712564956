import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { MetadataContext } from "../../../context/MetadataContext";
import { SKUTableContext } from "../../../context/SKUTableContext";
import getAssembly from "../../../util/legacy/assembly";
import SKUTableAPIClient from "../../api/SKUTableAPIClient";

class OrderToPrint extends React.Component {
  render() {
    return (
      <div className={this.props.classes.root}>
        <PrintSectionHeader
          timestamp={this.props.timestamp}
          pickup={this.props.pickup}
          name={this.props.name}
          email={this.props.email}
          comment={this.props.comment}
          tip={this.props.tip}
          classes={this.props.classes}
        />

        <React.Fragment>
          {this.props.order
            ? this.props.order.map((item, index) => {
                return (
                  <PrintSectionItem
                    key={index}
                    item={item}
                    classes={this.props.classes}
                  />
                );
              })
            : null}
        </React.Fragment>
      </div>
    );
  }
}

class PrintSectionHeader extends React.Component {
  render() {
    const fullWidthPrint =
      localStorage.getItem("Settings.fullWidthPrint") === "true" ? true : false;
    return (
      <div
        className={
          fullWidthPrint
            ? this.props.classes.fullWidthItem
            : this.props.classes.item
        }
      >
        <h1>{this.props.timestamp}</h1>
        <h1>Pickup Time: {this.props.pickup}</h1>
        <h3>{this.props.name}</h3>
        <h3>{this.props.email}</h3>
        <h3>{this.props.comment}</h3>
        <h3>Tip: {this.props.tip}</h3>
      </div>
    );
  }
}

class PrintSectionItem extends React.Component {
  assembly = getAssembly(this.props.item);

  render() {
    const fullWidthPrint =
      localStorage.getItem("Settings.fullWidthPrint") === "true" ? true : false;
    return [...Array(this.props.item.quantity).keys()].map((index) => {
      return (
        <div
          className={
            fullWidthPrint
              ? this.props.classes.fullWidthItem
              : this.props.classes.item
          }
          key={index}
        >
          <h3>{this.props.item.name}</h3>
          <h3>Comment: {this.props.item.details.comment}</h3>

          <ol className={this.props.classes.list}>
            {this.assembly.steps.map((item, index) => {
              return (
                <PrintItemStep
                  key={index}
                  item={item}
                  class={this.props.classes.listItem}
                />
              );
            })}
            {this.assembly.unknowns.length > 0 ? (
              <PrintItemStep
                item={this.assembly.unknowns}
                class={this.props.classes.listItem}
              />
            ) : null}
          </ol>
        </div>
      );
    });
  }
}

class PrintItemStep extends React.Component {
  render() {
    return <li className={this.props.class}>{this.props.item.join(", ")}</li>;
  }
}

const topItemCategory = ["signatureSides", "buildSides"];
const singleItemCategory = ["drinks", "giftCard"];

const OrderToPrintTextOnly = (props) => {
  const skuContext = React.useContext(SKUTableContext);

  const [mutatedOrder, setMutatedOrder] = React.useState([]);

  const isTopItem = (sku) => {
    if (skuContext) {
      return topItemCategory.includes(skuContext[sku].categoryId);
    }
    return false;
  };

  const sortByTop = (a, b) => {
    let a_ = 0;
    let b_ = 0;
    if (isTopItem(a.skus[0])) {
      a_ = 1;
    }

    if (isTopItem(b.skus[0])) {
      b_ = 1;
    }
    return b_ - a_;
  };

  React.useEffect(() => {
    if (props.order && skuContext) {
      let mutated = [];
      for (let item of props.order) {
        let skus = item.skus;
        let quantity = item.quantity;
        for (let sku of skus) {
          if (
            skuContext[sku] &&
            topItemCategory.includes(skuContext[sku].categoryId)
          ) {
            mutated.push({
              name: skuContext[sku].name,
              prep: [],
              details: {},
              skus: [].concat(sku),
              quantity: quantity,
            });
          }
        }
      }
      setMutatedOrder(mutated.concat(props.order));
    }
  }, [props.order, skuContext]);

  return (
    <pre id={"print-" + props.uuid}>
      {`ORDER ${props.uuid.substring(0, 8).toUpperCase()}\n` +
        `${props.timestamp}\n\n` +
        `Pickup Time:  ${
          props.pickup == "now" ? "now" : "*** " + props.pickup + " ***"
        }\n\n` +
        `${props.name}\n` +
        `${props.email}\n`}
      {props.comment ? `*** COMMENTS: ${props.comment}\n\n` : ""}
      {`Tip: ${props.tip}\n` + `*********************\n\n`}
      <React.Fragment>
        {mutatedOrder
          ? mutatedOrder.sort(sortByTop).map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {[...Array(item.quantity).keys()].map((o, index) => {
                    return (
                      <React.Fragment key={index}>
                        <OrderDetailTextOnly item={item} />
                        {`---------------------\n\n`}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })
          : null}
      </React.Fragment>
      {`*** END OF ORDER ${props.uuid.substring(0, 8).toUpperCase()} ***\n`}
    </pre>
  );
};

const OrderDetailTextOnly = (props) => {
  const skuContext = React.useContext(SKUTableContext);
  const metadataContext = React.useContext(MetadataContext);

  const isSingleItem = (sku) => {
    if (skuContext) {
      if (skuContext[sku]) {
        return singleItemCategory.includes(skuContext[sku].categoryId);
      } else {
        console.log(sku);
      }
    }
    return false;
  };

  const [assembly, setAssembly] = React.useState(null);
  const [emphasis, setEmphasis] = React.useState(null);

  React.useEffect(() => {
    if (isSingleItem(props.item.skus[0])) {
      setEmphasis(
        `*** ${skuContext[props.item.skus[0]].categoryName.toUpperCase()} ***`
      );
    } else {
      setEmphasis(null);
    }
  }, [props.item, skuContext]);

  React.useEffect(() => {
    setAssembly(
      props.item && skuContext && metadataContext
        ? getAssembly(props.item, skuContext, metadataContext.storeId)
        : null
    );
  }, [props.item, skuContext, metadataContext]);

  return (
    <React.Fragment>
      {`${emphasis ? emphasis : ""} ${props.item.name}\n\n`}
      {props.item.details.comment
        ? `Comment: ${props.item.details.comment}\n`
        : ""}
      {assembly
        ? assembly.steps.map((steps, index) => {
            return (
              <React.Fragment key={index}>
                {`${index + 1}. ${steps.join("\n   ")}\n`}
              </React.Fragment>
            );
          })
        : null}
      {assembly && assembly.unknowns.length > 0 ? (
        <React.Fragment>{` - ${assembly.unknowns.join(
          ", "
        )}\n`}</React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    float: "none",
    alignItems: "flex-start",
    flexWrap: "wrap",
    overflow: "visible",
    display: "block",
    margin: "0 !important",
    fontFamily: "monospace",
    fontSize: "16px",
    lineHeight: 1.5,
  },
  item: {
    width: `calc(50% - 4rem - 2px)`,
    float: "left",
    padding: "1rem",
    border: "1px solid #000",
    margin: "1rem",
    pageBreakInside: "avoid",
  },
  fullWidthItem: {
    width: "100%",
    float: "left",
    padding: "1rem",
    borderBottom: "1px solid #000",
    margin: "1rem",
    pageBreakInside: "avoid",
  },
  list: {
    margin: 0,
    padding: 0,
  },
  listItem: {
    marginLeft: "1rem",
  },
}));

const doRawBTPrint = (el) => {
  let prefix = "intent:";
  let suffix =
    "#Intent;scheme=rawbt;component=ru.a402d.rawbtprinter.activity.PrintDownloadActivity;package=ru.a402d.rawbtprinter;end;";
  let target = document.getElementById(el).innerText;
  let encodedURI = encodeURI(target);
  window.location.href = prefix + encodedURI + suffix;
};

const doNewRawBTPrint = (el) => {
  var S = "#Intent;scheme=rawbt;";
  var P = "package=ru.a402d.rawbtprinter;end;";
  let target = document.getElementById(el).innerText;
  var textEncoded = encodeURI(target);
  window.location.href = "intent:" + textEncoded + S + P;
  console.log("intent:" + textEncoded + S + P);
};

export default function PrintOrderButton(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();

  const componentRef = useRef();
  const oneTapPrint =
    localStorage.getItem("Settings.oneTapPrint") === "true" ? true : false;
  const handleRawBTPrint = async () => {
    doRawBTPrint("print-" + props.uuid);
    enqueueSnackbar("Printing to bluetooth printer...");
  };

  const handleNewRawBTPrint = () => {
    doNewRawBTPrint("print-" + props.uuid);
    enqueueSnackbar("Printing to bluetooth printer...");
  };

  const handleOnClick = async () => {
    if (props.onClick) {
      console.log("this ran");
      await props.onClick();
    }
    handleRawBTPrint();
  };

  return (
    <React.Fragment>
      <div style={{ display: "none" }}>
        {/* <OrderToPrint
          ref={componentRef}
          classes={classes}
          timestamp={props.timestamp}
          pickup={props.pickup}
          name={props.name}
          email={props.email}
          comment={props.comment}
          tip={props.tip}
          order={props.order}
        /> */}
        <OrderToPrintTextOnly
          uuid={props.uuid}
          timestamp={props.timestamp}
          pickup={props.pickup}
          name={props.name}
          email={props.email}
          comment={props.comment}
          tip={props.tip}
          order={props.order}
        />
      </div>
      <Button
        onClick={handleOnClick}
        variant={props.variant ? props.variant : "outlined"}
        color="primary"
        startIcon={<PrintIcon />}
      >
        {props.label ? props.label : "Print"}
      </Button>
    </React.Fragment>
  );
}
