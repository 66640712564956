import { API, graphqlOperation } from "aws-amplify";
import React from "react";

import { AuthContext } from "../../context/AuthContext";
import { MetadataContext } from "../../context/MetadataContext";

// const getTimestamp

export default function StoreMetadataAPIClient(props) {
  const authContext = React.useContext(AuthContext);
  const [storeMetadata, setStoreMetadata] = React.useState();

  const doFetch = () => {
    const fetchStoreMetadata = async () => {
      try {
        const getMetadata =
          `query MyQuery {
            getStoreMetadata(cognitoUsername: "` +
          authContext.username +
          `") {
              cognitoUsername
              storeId
              affiliateStoreIds
              mobileStoreId
            }
          }
          `;

        let storeMetadata = await API.graphql(graphqlOperation(getMetadata));
        if (storeMetadata) {
          setStoreMetadata(storeMetadata.data.getStoreMetadata);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchStoreMetadata();
  };

  React.useEffect(doFetch, []);

  return (
    <MetadataContext.Provider value={storeMetadata}>
      {props.children}
    </MetadataContext.Provider>
  );
}
