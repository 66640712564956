import Auth from "@aws-amplify/auth";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Dashboard, ExitToApp, Settings, Store } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import clsx from "clsx";
import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import SKUTableAPIClient from "../../components/api/SKUTableAPIClient";
import StoreMetadataAPIClient from "../../components/api/StoreMetadataAPIClient";
import { AuthContext } from "../../context/AuthContext";
import DashboardView from "./content/DashboardView";
import SettingsView from "./content/SettingsView";
import StockView from "./content/StockView";
import StoreView from "./content/StoreView";
import SideMenuItem from "./SideMenuItem";

const drawerWidth = 240;

const theme = createMuiTheme({
  palette: {
    primary: { main: "#263238", light: "#4f5b62", dark: "#000a12" },
    secondary: { main: "#212121", light: "#484848", dark: "#000000" },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overscrollBehavior: "none",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: 0,
    transform: "translateX(drawerWidth)",
  },
}));

export default function Layout(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const authContext = React.useContext(AuthContext);

  const sideMenuItems = [
    {
      title: "Dashboard",
      icon: <Dashboard color="secondary" />,
      link: "/dashboard",
    },
    {
      title: "Store",
      icon: <Store color="secondary" />,
      link: "/store",
    },
    {
      title: "Stock Control",
      icon: <RemoveShoppingCartIcon color="secondary" />,
      link: "/stock",
    },
    {
      title: "Settings",
      icon: <Settings color="secondary" />,
      link: "/settings",
    },
    {
      title: "Sign Out",
      icon: <ExitToApp color="secondary" />,
      onClick: async () => {
        try {
          await Auth.signOut();
        } catch (err) {
          console.error(err);
        }
      },
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <MuiThemeProvider theme={theme}>
      <StoreMetadataAPIClient>
        <SKUTableAPIClient>
          <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            autoHideDuration={3000}
          >
            <div className={classes.root}>
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap>
                    {authContext.attributes.name} Restaurant Portal
                  </Typography>
                </Toolbar>
              </AppBar>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </div>
                <Divider />
                <List>
                  {sideMenuItems.map((item, index) => (
                    <SideMenuItem key={index} item={item}></SideMenuItem>
                  ))}
                </List>
              </Drawer>
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: open,
                })}
              >
                <div className={classes.drawerHeader} />
                <BrowserRouter>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/dashboard" />
                    </Route>
                    <Route path="/dashboard" component={DashboardView} />
                    <Route path="/store" component={StoreView} />
                    <Route path="/stock" component={StockView} />
                    <Route path="/settings" component={SettingsView} />
                  </Switch>
                </BrowserRouter>
              </main>
            </div>
          </SnackbarProvider>
        </SKUTableAPIClient>
      </StoreMetadataAPIClient>
    </MuiThemeProvider>
  );
}
