import {
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core/";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";

import { AuthContext } from "../../../context/AuthContext";
import { MetadataContext } from "../../../context/MetadataContext";
import legacyConfig from "../../../legacyConfig";
import OrderControlDialog from "./OrderControlDialog";

const useStyles = makeStyles((theme) => ({
  danger: {
    color: theme.palette.error.dark,
  },
  secondary: {
    fontSize: "80%",
    width: "50%",
  },
}));

function createData(name, value) {
  return { name, value };
}

const DangerButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.error.dark),
    backgroundColor: theme.palette.error.dark,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
}))(Button);

export default function StoreView() {
  const classes = useStyles();

  const authContext = React.useContext(AuthContext);
  const metadataContext = React.useContext(MetadataContext);
  const [orderControl, setOrderControl] = React.useState(null);
  const [controlDialogOpen, setControlDialogOpen] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const fetchOrderControl = async (store) => {
    try {
      let result = await axios.post(
        legacyConfig.endpoint + "/getOrderControl",
        {
          store: store,
        }
      );
      if (result && result.data.Items) {
        console.log(result.data.Items[0]);
        setOrderControl(result.data.Items[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const doSync = () => {
    if (metadataContext) {
      fetchOrderControl(metadataContext.storeId);
    }
  };

  React.useEffect(() => {
    doSync();
  }, [metadataContext]);

  const handleUpdateOrdering = async (state, reason) => {
    setControlDialogOpen(false);
    try {
      console.log(reason);
      await axios.post(
        legacyConfig.endpoint + "/setOrderControl",
        {
          store: metadataContext.storeId,
          message: reason,
          state: state,
        },
        {
          headers: {
            Authorization: authContext.signInUserSession.idToken.jwtToken,
          },
        }
      );
      let mutated = JSON.parse(JSON.stringify(orderControl));
      mutated.STATE = state;
      mutated.MESSAGE = reason;
      setOrderControl(mutated);
      doSync();
      enqueueSnackbar("Successfully updated order control", {
        variant: "success",
        preventDuplicate: false,
      });
    } catch (err) {
      enqueueSnackbar("Failed to update order control", {
        variant: "error",
      });
    }
  };

  const handleControlDialogOpen = () => {
    setControlDialogOpen(true);
  };

  const handleControlDialogClose = () => {
    setControlDialogOpen(false);
  };

  const rows = [
    createData("Store ID", metadataContext ? metadataContext.storeId : null),
    createData("Username", authContext ? authContext.username : null),
    createData("Store Name", authContext ? authContext.attributes.name : null),
    createData("Email", authContext ? authContext.attributes.email : null),
    createData("Address", authContext ? authContext.attributes.address : null),
    createData(
      "Affiliated Stores",
      metadataContext ? metadataContext.affiliateStoreIds : null
    ),
  ];

  return (
    <React.Fragment>
      <List>
        {rows.map((row, index) => (
          <React.Fragment key={index}>
            <ListItem alignItems="flex-start" disableGutters={true}>
              <ListItemText primary={row.name} secondary={row.value} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <List>
        <ListItem disableGutters={true} alignItems="flex-start">
          <ListItemText
            primary="Ordering Control"
            primaryTypographyProps={{ className: classes.danger }}
            secondaryTypographyProps={{ className: classes.secondary }}
            secondary={`Last event: ${
              orderControl ? orderControl.DATE : ""
            }\nMessage: ${orderControl ? orderControl.MESSAGE : ""}`}
          />
          <ListItemSecondaryAction>
            {orderControl ? (
              orderControl.STATE ? (
                <DangerButton
                  variant="contained"
                  onClick={handleControlDialogOpen}
                >
                  Manage
                </DangerButton>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleUpdateOrdering(true, "Reinstated");
                  }}
                >
                  Reinstate
                </Button>
              )
            ) : (
              <CircularProgress size={24} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <OrderControlDialog
          open={controlDialogOpen}
          handleClose={handleControlDialogClose}
          handleDisableOrdering={handleUpdateOrdering}
          store={authContext ? authContext.username : null}
        />
      </List>
    </React.Fragment>
  );
}
