import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { Component } from "react";

export class SideMenuItem extends Component {
  render() {
    return (
      <ListItem
        button
        onClick={this.props.item.onClick}
        component="a"
        href={this.props.item.link ? this.props.item.link : "#"}
      >
        <ListItemIcon>{this.props.item.icon}</ListItemIcon>
        <ListItemText primary={this.props.item.title} />
      </ListItem>
    );
  }
}

export default SideMenuItem;
