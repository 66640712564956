/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:f46b67c8-bf01-4703-ba41-dcbf84101a1f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ZOrvu698g",
    "aws_user_pools_web_client_id": "7jqgtrq4uc9gcpt1olfsullgls",
    "oauth": {},
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://2rzcyg3darfsjaoamryc3zn35a.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-jmsoemr22zeqbjyhc3tmdqigke"
};


export default awsmobile;
