import { Button, Dialog, DialogContentText, TextField } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - 2*${theme.spacing(3)})`,
    margin: theme.spacing(3),
  },
}));

export default function NotifyDelayDialog(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(10);

  const handleChange = (event, newValue) => {
    setValue((newValue + 1) * 5);
  };

  const handleSend = () => {
    props.handleSend(props.id, `${value} minutes`);
    setValue(10);
  };

  const handleClose = () => {
    setValue(10);
    props.handleClose();
  };

  const marks = [...Array(12).keys()].map((index) => {
    return {
      value: index,
      label: String((index + 1) * 5),
    };
  });

  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DialogTitle id="notify-delay-dialog-title" onClose={props.handleClose}>
        {`Notify Order ${props.id.substring(0, 8).toUpperCase()} of delay`}
      </DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Typography gutterBottom>
            Estimate the amount of extra time you will need to complete this
            order:
          </Typography>
          <Typography
            gutterBottom
            align="center"
            variant="h5"
          >{`${value} minutes`}</Typography>
          <Slider
            defaultValue={1}
            aria-labelledby="discrete-slider-always"
            min={0}
            max={11}
            step={1}
            marks={marks}
            onChange={handleChange}
            scale={(x) => 5 * (x + 1)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSend}
          color="primary"
          disabled={value == null}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
